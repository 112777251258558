<template>
  <div style="position: relative">
    <mx-preloader style="top: -7px; background-color: #ffffff; padding-top: 60px" :loader-status="preloader" :logo="false"/>
    <v-autocomplete
        v-model="result"
        :disabled="isUpdating"
        :items="items"
        outlined
        chips
        color="blue-grey lighten-2"
        :label="$t('document.customer.select')"
        item-text="company_name"
        item-value="uid"
        multiple
        hide-details
    >
      <template v-slot:selection="data">
        <v-chip style="height: 48px; padding: 0 18px 0 18px;" label v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">
          <div style="margin-right: 10px;">
            <div>{{ data.item.company_name }}</div>
            <small style="margin-top: -4px; display: block; color: #4a4a4a">
              {{ data.item.first_name }} {{ data.item.last_name }}
            </small>
          </div>
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-avatar tile>
            <v-img class="rounded" :src="data.item.avatar === null ? '/assets/images/avatar.jpg' : data.item.avatar"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <div style="font-weight: 500;">{{ data.item.company_name }}</div>
              <small style="color: #838282">{{ data.item.first_name }} {{ data.item.last_name }}</small>
            </v-list-item-title>
            <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Array,
      default: function () {
        return []
      },
    }
  },
  data() {
    return {
      preloader: true,
      autoUpdate: true,
      result: [],
      isUpdating: false,
      name: 'Midnight Crew',
      items: [],
    }
  },
  watch: {
    isUpdating (val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000)
      }
    },
    result() {
     this.resultSelect()
    },
  },
  created() {
    this.result = this.selected

    this.$store.dispatch('customersCrm', this.page).then(() => {
      this.items = this.$store.getters.customersCrm.data.map(customer => {
        customer.name = customer.first_name + customer.last_name
        return customer
      })

      setTimeout(() => {
        this.preloader = false
      },300)

      this.resultSelect()
    })
  },
  methods: {
    remove (item) {
      const index = this.result.indexOf(item.uid)
      if (index >= 0) this.result.splice(index, 1)
    },
    resultSelect() {
      this.$emit('result', this.result)
    }
  },
}
</script>

<style scoped>

</style>
